/* eslint-disable default-param-last */
/* eslint-disable max-len */
import isValid from 'date-fns/isValid';
import isPostalCode from 'validator/lib/isPostalCode';
import _isAlphanumeric from 'validator/lib/isAlphanumeric';
import __isFloat from 'validator/lib/isFloat';
import _isInt from 'validator/lib/isInt';
import isURL from 'validator/lib/isURL';
import {
  isValidPhoneNumber,
  parsePhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import cpfCheck from 'cpf-check';
import get from 'lodash.get';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

// @ts-expect-error hide this
const _isFloat = __isFloat.default || __isFloat;

const { validate: cpfValidate } = cpfCheck;

const isPlainObject = (obj: any) => {
  // Basic check for Type object that's not null
  if (typeof obj === 'object' && obj !== null) {
    // If Object.getPrototypeOf supported, use it
    if (typeof Object.getPrototypeOf === 'function') {
      const proto = Object.getPrototypeOf(obj);
      return proto === Object.prototype || proto === null;
    }

    // Otherwise, use internal class
    // This should be reliable as if getPrototypeOf not supported, is pre-ES5
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  // Not an object
  return false;
};

export const isEmpty = (value: any) => value === undefined || value === null || value === '';
const join = (rules: any) => (value: any, data: any, params: any) =>
  rules.map((rule: any) => rule(value, data, params)).filter((error: any) => !!error)[0];

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export function cpf(value: any) {
  if (!isEmpty(value) && !cpfValidate(value)) {
    return 'Invalid CPF';
  }
}

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export function isAlphanumeric(value: any) {
  if (!isEmpty(value) && !_isAlphanumeric(value)) {
    return 'Value can only be alphanumeric';
  }
}

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export function isString(value: any) {
  if (!isEmpty(value) && typeof value !== 'string') {
    return 'Value can only be an string';
  }
}

export function isFloat(options?: any, fieldName = 'number') {
  return (value: any) => {
    if (!isEmpty(value)) {
      if (!_isFloat(String(value), options)) {
        if (options && options.max) {
          return options.msgMax || `The ${fieldName} is greater than ${options.max}`;
        }

        if (options && options.min) {
          return options.msgMin || `The ${fieldName} is less than ${options.min}`;
        }

        if (options && options.gt) {
          return options.msgGt || `The ${fieldName} has to be greater than ${options.gt}`;
        }

        if (options && options.lt) {
          return options.msgLt || `The ${fieldName} has to be less than ${options.lt}`;
        }

        return `The ${fieldName} is invalid`;
      }
    }
  };
}
export function isInt(options?: any) {
  return (value: any) => {
    if (!isEmpty(value)) {
      if (!_isInt(String(value), options)) {
        if (options && options.max) {
          return options.msgMax || `The number is greater than ${options.max}`;
        }

        if (options && options.min) {
          return options.msgMin || `The number is less than ${options.min}`;
        }

        if (options && options.gt) {
          return options.msgGt || `The number has to be greater than ${options.gt}`;
        }

        if (options && options.lt) {
          return options.msgLt || `The number has to be less than ${options.lt}`;
        }

        return 'The number is invalid';
      }
    }
  };
}

export function url(options: any) {
  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  return (value: any) => {
    // Let's not start a debate on email regex. This is just for an example app!
    if (!isEmpty(value) && !isURL(value, options)) {
      if (options && options.require_protocol) {
        return `Invalid url / hostname, protocol is mandatory, valid protocols are: ${
          options.protocols || 'http, https, ftp'
        }`;
      }
      return 'Invalid url / hostname';
    }
  };
}

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export function phone(value: any) {
  // Let's not start a debate on email regex. This is just for an example app!
  if (!isEmpty(value)) {
    const phoneNumber = parsePhoneNumberFromString(value);
    if (!isValidPhoneNumber(value) || !phoneNumber?.isValid()) {
      return 'Invalid phone number';
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function phoneNumberBelongsTo(localeField: any) {
  // Let's not start a debate on email regex. This is just for an example app!
  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  return (value: any, data: any) => {
    if (!isEmpty(value)) {
      const phoneNumber = parsePhoneNumber(value);
      if (
        !phoneNumber ||
        (phoneNumber.country &&
          getCountryCallingCode(phoneNumber.country) !==
            getCountryCallingCode(get(data, localeField, 'US')))
      ) {
        return `Invalid phone number for ${get(data, localeField, 'US')}`;
      }
    }
  };
}

export function postalCode(localeField: any, datafield = false) {
  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  return (value: any, data: any) => {
    try {
      if (
        !isEmpty(value) &&
        !isPostalCode(
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          datafield ? get(value, datafield, value) : value,
          get(data, localeField, 'US')
        )
      ) {
        return 'Invalid postal code';
      }
    } catch (e) {
      return undefined;
    }
  };
}

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export function email(value: any) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  }
}

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export function date(value: any) {
  if (typeof value === 'string') {
    value = new Date(value);
  }
  if (!isEmpty(value) && !isValid(value)) {
    return 'Invalid date';
  }
}

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export function required(value: any) {
  if (isEmpty(value)) {
    return 'Required';
  }

  if (Array.isArray(value)) {
    if (!value.some((item) => !isEmpty(item))) {
      return 'Required';
    }
  }

  if (typeof value === 'object') {
    if (isPlainObject(value) && !Object.keys(value).length) {
      return 'Required';
    }
  }
}

export function requiredIf(condition: any) {
  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  return (value: any, data: any, params: any) => {
    if (condition(value, data, params)) {
      if (isEmpty(value)) {
        return 'Required';
      }
    }
  };
}

export function minLength(min: any) {
  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  return (value: any) => {
    if (!isEmpty(value) && String(value).length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max: any) {
  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  return (value: any) => {
    if (!isEmpty(value) && String(value).length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

// @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
export function integer(value: any) {
  if (!isEmpty(value) && !Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
}

export function oneOf(enumeration: any) {
  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  return (value: any) => {
    if (!enumeration.includes(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function match(field: any) {
  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  return (value: any, data: any) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    }
  };
}

function isPromise(obj: any) {
  return (
    !!obj &&
    (typeof obj === 'object' || typeof obj === 'function') &&
    typeof obj.then === 'function'
  );
}

export function createValidator(rules: any, params: any) {
  return async (data = {}, rest: any) => {
    const errors = {};
    const promsies: any = [];
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      let error;
      try {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        error = rule(data[key], data, { key, ...params, ...rest });
      } catch (e: any) {
        error = e.toString();
      }
      if (error && !isPromise(error)) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        errors[key] = error;
      }

      if (isPromise(error)) {
        promsies.push(
          error
            .then((r: any) => {
              if (r) {
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                errors[key] = r;
              }
            })
            .catch((e: any) => {
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              errors[key] = e;
            })
        );
      }
    });
    await Promise.all(promsies);
    return errors;
  };
}
